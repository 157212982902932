import { promiseAsyncAuto } from '../asyncAuto.js'
import { getSellingGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  selectionAndEntityFilterApiCache,
  selectionAndEntityCloudTagApiCache,
} from '../api/bffApi.js'
import { getCommonRequestQueue } from './common.js'
import { generateRealSelectBffFilterInfo } from '../utils'

const getSellingRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, cloudTagParams } = getSellingGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ requestInfo }),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'selling',
      params: goodsParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})

      const result = await promiseAsyncAuto({
        bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
        bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
      })
      cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
    },
    // 面包屑 currentCat
    currentCat: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, null)

      cb(null, {
        bffCurrentCat: true,
        _from: 'bff',
      })
    },
  }
  return requestQueue
}

export {
  getSellingRequestQueue
}
