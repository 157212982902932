import { getViewedGoods, getCommonParams, getVuexInfo, getListMainParams, getPicNavParams } from './common.js'
import { getCurrentToRoute } from '../utils'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'
import { getRefreshListStrategyParams } from './refreshListStrategyParams.js'

const getGoodsParams = (requestInfo, newSrcTabPageId, toRouteArg) => {
  const toRoute = toRouteArg || getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { goods, catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'realListPage')

  const {
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)
  let params = {
    ...mainParams,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    choosed_nav_pos: query.pic_nav_pos || '',
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
    entranceType: query.entranceType || '',
    sort: query.sort || '',
    source: query.source || '',
    styleType: query.styleType || '',
    source_status: query.source_status || '',
    adp: getGoodsAdp(query),
    filter_goods_infos: getViewedGoods(toRoute, requestInfo, goods),
    _type: 'entity',

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
    ...refreshListStrategyParams,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

const getCateId = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  return mainParams.cat_id
}

// 筛选相关
const getFilterParams = (requestInfo, toRouteArg) => {
  const toRoute = toRouteArg || getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'realListPage')
  const {
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,

    maxPrice,
    minPrice,
    tagIds,
    filterMallCode,
    urlMallCode,
    // lastParentCatId,
    chooseIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    quickship,
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    last_parent_cat_id: query.last_parent_cat_id,
    choosed_ids: chooseIds, // 已选中的分类id和其所有父类id用逗号拼接
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    sort: query.sort || '',
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    ...refreshListStrategyParams,
    _type: 'entity',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = (requestInfo, toRouteArg) => {
  const toRoute = toRouteArg || getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  const {
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    urlMallCode,
    filterMallCode,
    tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday
  } = getCommonParams(query, searchKeywords)


  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    ...mainParams,
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    sort: query.sort || '',
    page_name: catInfo.pageName,
    page: query.page || 1,
    limit: query.limit || 10,
    kidRandom: query.kid_random || '', // 避免缓存
    styleType: query.styleType || '',
    _type: 'entity'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

const getCccOperationRequestInfo = (requestInfo) => {
  const cateId = getCateId(requestInfo)
  const sceneKeys = ['mobile_seo_text', 'mobile_buyer_show']

  return {
    form: {
      cate_id: cateId,
      cate_type: 2
    },
    sceneKeys
  }
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns
 */
export const getRealGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  const cateId = getCateId(requestInfo)
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    picNavParams: getPicNavParams(requestInfo, 'entity'),
    cloudTagParams: getCloudTagParams(requestInfo),
    cccOperationParams: getCccOperationRequestInfo(requestInfo),
    policyBannerParams: {
      cateType: '4',
      cateId: cateId,
      cccPageType: 'realSelectListPage'
    },
    cccSeoParams: {
      cateId,
      cateType: 2
    }
  }
}

// 类目反馈半屏用
export const getRealHalfGenerateBffParams = (requestInfo, newSrcTabPageId, toRoute) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId, toRoute),
    filterParams: getFilterParams(requestInfo, toRoute),
    cloudTagParams: getCloudTagParams(requestInfo, toRoute),
  }
}

