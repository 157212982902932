
import { getCommonParams, getVuexInfo, getListMainParams, fixSearchSource, } from './common.js'
import { isSearch, isAllSearch, isStoreSearch, isBrandSearch, isTopTrendResultPage, cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'
import { getCurrentToRoute } from '../utils'
import { getRefreshListStrategyParams } from './refreshListStrategyParams.js'

// 主搜POSKEY
const getSearchPoskey = (query) => {
  const isInListSearch = !(query.sil_without_entry || !query.sil_type) // 是否是列表页内搜索
  return !isInListSearch ? 'SearchPageSort' : 'ListSearchSort'
}

// context_info参数处理
const getContextInfo = ({ catInfo }) => {
  let srcIdentifierPreSearch = {}
  let newSrcTabPageId = typeof catInfo?.new_src_tab_page_id === 'string' ? catInfo?.new_src_tab_page_id : ''
  try {
    srcIdentifierPreSearch = gbCommonInfo?.src_identifier_pre_search || ''
  } catch (error) {
    srcIdentifierPreSearch = {}
  }

  return {
    ...srcIdentifierPreSearch,
    src_module: catInfo?.src_module || '',
    src_tab_page_id: newSrcTabPageId.replace(/\d+$/, ''),
  }
}

// 商品接口入参
const getGoodsParams = (requestInfo, newSrcTabPageId) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'searchListPage')

  const { type, mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const { 
    quickship,
    filter,
    catId,
    filterBrandIds,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
    serviceType,
    forceNotCorrect,
    listCatId,
    // clickedQueries,
    forceSuggest,
    storeCode,
    intentChannelId,
    default_child_id,
    default_child_sex,
    default_child_birthday,
  } = getCommonParams(query, searchKeywords)

  /**
   * TODO: 暂时不走bff
   * cate_ids: getCateIds({ catInfo, stateResults }),
   */

  /**
   * 会员态怎么办？TODO:
   * reqSheinClub: atomicParams.reqSheinClub,
   * isPaid: atomicParams.isPaid,
   */

  let params = {
    ...mainParams,
    quickship,
    filter,
    cat_id: catId,
    filterBrandIds,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code: mallCode,
    force_not_correct: forceNotCorrect,
    adp: getGoodsAdp(query),
    is_force: query.is_force || '',
    sort: query.sort || '',
    source: query.source || '',
    source_status: query.source_status || '',

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
    ...refreshListStrategyParams,
    context_info: getContextInfo({ query, catInfo })
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      service_type: serviceType,
      list_cat_id: listCatId,
      // clicked_queries: clickedQueries,
      force_suggest: forceSuggest,
      src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
      poskey: getSearchPoskey(query),
    })

    if (query.page == 2 && catInfo.secondProductInfos) {
      const { goods } = getVuexInfo(toRoute)
      // 如果有点后刷商品 则不在传入预请求的参数
      const hasExtraGoods = goods.some(item => ['request_early', 'request_early_back'].includes(item.divideTime))
      !hasExtraGoods && Object.assign(params, {
        secondProductInfos: catInfo.secondProductInfos
      })
    }

    if (isAllSearch(type, query)) { // 全站搜索
      Object.assign(params, {
        scene: 'all',
        search_source: fixSearchSource(query),
      })
    } else if (isTopTrendResultPage(type, query)) { // 趋势页搜索
      Object.assign(params, {
        scene: 'trendChannel',
        search_source: fixSearchSource(query),
      })
    } else if (isStoreSearch(type, query)) { // 店铺搜索
      Object.assign(params, {
        scene: 'store',
        store_code: storeCode,
        ignore_direct: 'true',
      })
    } else if (isBrandSearch(type, query)) { // 专题搜索
      Object.assign(params, {
        intent_channel_id: intentChannelId,
        ignore_direct: 'true',
        search_source: fixSearchSource(query),
      })
    }
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选接口入参（图文导航，属性，分类，tsp筛选，标签云）
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { type, mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'searchListPage')
  const { 
    quickship,
    filter,
    catId,
    filterBrandIds,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    serviceType,
    forceNotCorrect,
    listCatId,
    // clickedQueries,
    forceSuggest,
    intentChannelId,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    quickship,
    filter,
    catId,
    filterBrandIds,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    chooseTag: tagIds,
    mallCode: filterMallCode,
    forceNotCorrect,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    cancelFilterTagIds: filterTagIds,
    needFilter: '1', // 是否需要filter数据：0 不需要 1 需要(默认) 
    needNavIndex: '1', // 是否需要图文导航数据：0 不需要 1 需要(默认)
    needTagCloud: '1', // 是否需要标签云数据：0 不需要 1 需要(默认)
    positionAbt: '', // 筛选信息流ABT
    maxDisc: '', // 最大折扣
    mixDisc: '', // 最小折扣
    enterMallCode: urlMallCode, // 入口默认的mallcode，用来屏蔽mall标签云
    cancelFilter: '', // 用户取消的筛选项
    chooseIds: query.choose_ids, // 已选中的分类id和其所有父类id用逗号拼接
    kidRandom: query.kid_random || '', // 避免缓存
    showAllCategory: query.showAllCategory || '', // 是否展示全部分类 // 单层级类目，需要返回全部类目
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    ...refreshListStrategyParams,
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      serviceType,
      listCatId,
      // clickedQueries,
      forceSuggest,
      poskey: getSearchPoskey(query)
    })
  }

  if (isAllSearch(type, query)) { // 全站搜索
    Object.assign(params, {
      scene: 'all', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
    })
  } else if (isStoreSearch(type, query)) { // 店铺搜索
    Object.assign(params, {
      scene: 'store', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
      storeCode: query.store_code
    })
  } else if (isBrandSearch(type, query)) { // 专题搜索
    Object.assign(params, {
      intentChannelId,
    })
  } else if (isTopTrendResultPage(type, query)) { // 趋势页搜索
    Object.assign(params, {
      scene: 'trendChannel', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
    })
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 顶部卡片接口入参
const getBannerCardParams = (requestInfo, keywords) => {
  const query = requestInfo.query || {}
  return {
    keywords,
    scene: query?.search_type === 'pageTrend' ? 'trendChannel' : '',
  }
}

const getKeywords = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  return mainParams?.keywords || ''
}


export const getCccOperationRequestInfo = () => {
  const sceneKeys = ['mobile_feedback_entry', 'mobile_guided_search']

  return {
    form: {
      cate_id: '',
      cate_type: 4
    },
    sceneKeys
  }
}

/**
 * 
 * @param {Object} requestInfo 
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query 
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns 
 */
export const getSearchGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  const keywords = getKeywords(requestInfo)
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    bannerCardParams: getBannerCardParams(requestInfo, keywords),
    cccOperationParams: getCccOperationRequestInfo(requestInfo),
    cccSeoParams: {
      cateId: keywords,
      cateType: 5
    }
  }
}
