import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'

import {
  selectionAndEntityPicNavApiCache,
  languageApiCache,
  cccFeedbackApiCache,
  sheinClubInfoCache,
  cccSeoApiCache,
  cccOperationApiCache,
  policyBannerApiCache
} from '../api/bffApi.js'


const CCC_CONFIG = 'ccc_config'
const POLICY_BANNER_V2 = 'policy_banner_v2'

const bffSwitchVersion = isSwitchBffApiVersion([
  CCC_CONFIG,
  POLICY_BANNER_V2,
])

const isCccConfigSwitchBff = bffSwitchVersion?.[CCC_CONFIG]
const isPolicyBannerSwitchBff = bffSwitchVersion?.[POLICY_BANNER_V2]

// 政策条
const getBffPolicyBanner = ({ requestInfo, policyBannerParams = {} } = {}) => { 
  // 页面范围
  return {
    bffPolicyBanner: async cb => {
      if (!isPolicyBannerSwitchBff || requestInfo.type !== 'firstload') {
        return cb(null, {})
      }
      
      const result = await policyBannerApiCache.request(policyBannerParams)
      cb(null, result)
    }
  }
}

// 图文导航
const requestPicNav = ({ requestInfo, picNavParams }) => {
  const hidePicNav = requestInfo.query.is_hide_image_nav === '1'
  if (hidePicNav) {
    return new Promise(resolve => {
      resolve({})
    })
  }

  return selectionAndEntityPicNavApiCache.request(picNavParams)
}

// 负反馈
const getCccFeedbackData = () => {
  return {
    cccFeedbackData: async (cb) => {
      const result = await cccFeedbackApiCache.request({ v: '1.0' })
      cb(null, result)
    }
  }
}

// 多语言
const getBffLanguage = (pages, noNeedLanguage) => {
  return {
    bffLanguage: async (cb) => {
      if (noNeedLanguage) {
        return cb(null, {})
      }

      const result = await languageApiCache.request({ pages })
      
      const bffLanguage = result?.code == '0' && result?.info?.result || {}
      bffLanguage._fromBff = true
      cb(null, bffLanguage)
    }
  }
}

// 付费会员
const getSheinClubInfo = () => {
  return {
    sheinClubInfo: async (cb) => {
      const res = await sheinClubInfoCache.request({
        params: {}
      })
      const { is_paid = 0 } = res.info || {}

      const result = {
        sheinClubType: 3,
        reqSheinClub: true,
        isPaid: is_paid,
        _from: 'bff',
      }

      cb(null, result)
    }
  }
}

// ccc Seo 
const getCCCSeo = (params) => {
  return {
    cccSeo: async (cb) => {
      if (!params) {
        return cb(null, {})
      }

      const result = await cccSeoApiCache.request({
        params
      })


      cb(null, result || {})
    }
  }
}
// ccc operation
const getCccOperation = (params) => {
  return {
    cccOperation: async (cb) => {
      if (!params?.form) {
        return cb(null, {})
      }

      const sceneKeys = isCccConfigSwitchBff ? params.sceneKeys : ['mobile_seo_text']

      const form = params.form || {}

      const result = await cccOperationApiCache.request({
        params: {
          cateId: form.cate_id,
          cateType: form.cate_type,
          sceneKey: sceneKeys.join(),
          listsId: '',
          isPreview: 0,
          storeCode: form.storeCode,
        }
      })
      cb(null, result || {})
    }
  }
}

const getCommonRequestQueue = ({ requestInfo, isStore, cccSeoParams, cccOperationParams, policyBannerParams } = {}) => {
  let requestList = {
    // 负反馈
    ...getCccFeedbackData(),
  }
  if(isStore && requestInfo.type !== 'nextpage'){
    if(cccOperationParams.form && !cccOperationParams.form.cate_id){
      Object.assign(cccOperationParams.form, { storeCode: requestInfo.query?.store_code })
      return Object.assign(requestList, {
        ...getCccOperation(cccOperationParams),
      })
    }
    
  }

  if (requestInfo.type !== 'nextpage') {
    requestList = Object.assign(requestList, {
      ...getCccOperation(cccOperationParams),
      ...getCCCSeo(cccSeoParams),
      // 政策条
      ...getBffPolicyBanner({ requestInfo, policyBannerParams }),
      // 多语言
      ...getBffLanguage(['filter', 'product_list', 'product_item', 'coupon', 'coupon_item', 'survey_question']),
      // 付费会员
      ...getSheinClubInfo()
    })
  }

  return requestList
}

export {
  requestPicNav,
  getSheinClubInfo,
  getCccFeedbackData,
  getCccOperation,
  getCommonRequestQueue,
}
