import schttp from 'public/src/services/schttp'
import { ApiCache } from '../../ApiCache/index'
import { getCurrentToRoute, getPdeParams, handleGoodsImgLessen, preloadPicTopNav } from '../utils.js'
import { PRODUCT_LIST_API_URL_MAP, NOT_CACHE_KEYS } from '../constant.js'
import { preloadImg } from '../../../utlis/prefetch.js'
import { customMarkPoint } from 'public/src/services/mark/index.js'

export let abortionInstance = null
export const apiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params, { toRouteForward }) => {
    if (abortionInstance) {
      abortionInstance.abort()
    }
    if (!params.withoutAbort) {
      abortionInstance = new SchttpAbortCon()
    } else {
      abortionInstance = null
    }

    const route = toRouteForward || getCurrentToRoute()
    
    const url = PRODUCT_LIST_API_URL_MAP[route.name]
    if (!url) {
      throw new Error('未找到对应的 url')
    }
    
    const isFirstLoad = params?.requestType === 'firstload'

    const markApiEnd = isFirstLoad && customMarkPoint({ eventName: 'api_request_time_goods' })
    const res = await schttp({
      url,
      params,
      headers: getPdeParams(),
      signal: params.withoutAbort ? undefined : abortionInstance.signal,
      isPreLoad: true,
    })
    isFirstLoad && markApiEnd()

    function handlePreloadImgs() {
      // 对 goods 中的前 6 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.goods?.slice(0, 6) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_image?.[0]?.medium_image).filter(img => img && img !== 'filteredSpuImg')
      // 以及对图文导航的首屏图片进行预取
      preloadPicTopNav({ nodeRes: res, toRoute: route })
      // 取搜索店铺卡片图片
      const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      if (trendCardImgs.length) {
        trendCardImgs.push(trendCargBg)
      }
      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods, isFirstLoad }),
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...spuImages], designWidth: 338 }), 
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        preloadImg({ imgs: [...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ])
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key
  notCacheKeys: NOT_CACHE_KEYS,
})

export const clearNodeApiCache = () => { 
  apiCache.clear()
}

