/* eslint-disable require-atomic-updates */

import { defaultRequestInfo, setCurrentToRoute, PointerOverGoodsRequest, generateRouterParams, preloadPicTopNav } from './utils'
import { PRODUCT_LIST_ROUTE_NAME, ROUTE_REGEX } from './constant.js'
import generateParams from './generateParams'
import { getSearchGenerateBffParams } from './bffParamsHandle/index.js'
import { getBffRequestQueue } from './bffRequestQueue/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { abortionInstance, apiCache, clearNodeApiCache } from './api/nodeApi.js'
import {
  dailyNewFilterAbortionInstance,
  dailyNewCloudTagAbortionInstance,
  cccFeedbackAbortionInstance,
  goodsAbortionInstance,
  hypernymGoodsAbortionInstance,
  searchFilterAbortionInstance,
  selectionAndEntityFilterAbortionInstance,
  selectionAndEntityPicNavAbortionInstance,
  selectionAndEntityCloudTagAbortionInstance,
  showRecentDaysTagAbortionInstance,
  pickedFilterAbortionInstance,
  pickedCloudTagAbortionInstance,
  sheinPicksNavTabAbortionInstance,
  feedbackRecFilterAbortionInstance,
  feedbackRecCloudTagAbortionInstance,
  searchBannerCardAbortionInstance,
  clearGoodsApiCache,
  clearHypernymGoodsApiCache,
  clearSearchFilterApiCache,
  clearSearchBannerCardApiCache,
  clearRelatedSearchApiCache,
  clearSelectionAndEntityFilterApiCache,
  clearSelectionAndEntityCloudTagApiCache,
  clearSheinPicksNavTabApiCache,
  discountPageInfoApiCache,
} from './api/bffApi.js'
import { promiseAsyncAuto } from './asyncAuto.js'
import { userTracekPreCheckEnd } from 'public/src/pages/common/userActionTracker/index.js'
import { isGeneralProductList } from './bffParamsHandle/utils'


function clearApiCache() {
  apiCache.clear()
}

/**
 * 获取商品列表数据的接口调用
 * @customRequestQueue 自定义队列, 可以在指定的时机调用，例如在获取筛选数据之后就调用
 * */
const requestProductListApi = async ({ requestInfo, newSrcTabPageId, toRoute, useNodeApi, customRequestQueue = {} }) => {
  if (typeof window === 'undefined') return

  // const requestStrategy = requestInfo.query.isClickRefresh ? '点刷类型' : '分页类型'

  // console.log(`%c 当前请求类型为: ${requestStrategy} 其中 page: ${requestInfo.query.page} limit: ${requestInfo.query.limit} `, 'background: #222; color: #bada55')
  if(toRoute && toRoute.name === 'page_store'){ // 店铺先统一设置， 如果列表也可以统一设置， 可以放开判断
    setCurrentToRoute(toRoute)
  }
  const nodeRequest = getNodeRequest(requestInfo, newSrcTabPageId, toRoute)
  const bffRequestQueue = await getBffRequestQueue(requestInfo, toRoute, newSrcTabPageId)
  const isBff = !useNodeApi && Object.keys(bffRequestQueue).length
  const isUserBehavior = toRoute?.path?.match(ROUTE_REGEX.pickedProducts)
  const isPicked = toRoute?.path?.match(ROUTE_REGEX.picked)
  const useBffCccConfig = isSwitchBffApiVersion(['ccc_config'])?.ccc_config ? 1 : undefined
  const useBffPolicyBanner = isSwitchBffApiVersion(['policy_banner_v2'])?.policy_banner_v2 ? 1 : undefined

  try {
    if (isBff) {
      // 传递 useBffXXX 参数，在开启 bff 下屏蔽旧的node链路不调用相应接口
      const data = await promiseAsyncAuto({
        nodeRes: nodeRequest({
          useBffProducts: 1,
          useBffFilters: 1,
          useBffCccConfig,
          useBffPolicyBanner,
          withoutAbort: requestInfo.query?.withoutAbort,
          toRouteForward: toRoute
        }),
        ...bffRequestQueue,
        bffFilterInfo: ['nodeRes', 'filterData', (result, cb) => {
          if (!result.filterData) return cb(null, {})
          // filter相关数据都在这里处理。
          preloadPicTopNav({ nodeRes: result.nodeRes, toRoute, bffFilterInfo: result.filterData, isBff: true })
          cb(null, result.filterData)
        }],
        ...customRequestQueue,
      })
      const bffGoodsResult = data.goods || {}
      const bffCccFeedbackResult = data.cccFeedbackData || {}
      const bffSearchBannerCardResult = data.searchBannerCard || {}
      const bffLanguage = data.bffLanguage || {}
      const sheinClubInfo = data.sheinClubInfo || {}
      const cccSeo = data.cccSeo || {}
      const parentCats = data.parentCats || {}
      const bffCurrentCat = data.currentCat?.bffCurrentCat
      const bffTabs = data.tabs || {}
      const bffPolicyBanner = data.bffPolicyBanner || {}
      const bffCccOperationSeo = data.cccOperationSeo || {}
      const bffCccOperationResult = data.cccOperation || {}

      // 需要把筛选链路请求的数据暴露出去(根据exposeKeys按需提取)，给后面链路使用
      let needExposeFilterInfo = {}
      const filterInfoWithNodeRes = data._filterRenderHandle?.filterInfoWithNodeRes || {}
      const exposeKeys = data._filterRenderHandle?.exposeKeys || []
      if (Object.keys(filterInfoWithNodeRes).length && exposeKeys.length) {
        exposeKeys.forEach(key => {
          needExposeFilterInfo[key] = filterInfoWithNodeRes[key]
        })
      }

      const resultData = {
        ...(data.nodeRes || {}),
        ...(bffLanguage._fromBff ? { language: bffLanguage } : {}),
        ...(Object.keys(parentCats)?.length ? { parentCats } : {}),
        ...(Object.keys(sheinClubInfo)?.length ? { sheinClubInfo } : {}),
        ...(Object.keys(cccSeo)?.length ? { cccSeo } : {}),
        bffFilterInfo: data.bffFilterInfo || {},
        bffProductsInfo: bffGoodsResult.code === '0' ?  { code: '0', ...bffGoodsResult.info } : {},
        bffCccFeedbackInfo: bffCccFeedbackResult.code === '0' ? bffCccFeedbackResult.info : {},
        bffCccOperation: bffCccOperationResult?.code === '0' ?  { code: '0', ...bffCccOperationResult?.info } : {},
        bffTabsInfo: bffTabs.code === '0' ? bffTabs.info : {},
        // _filterRenderHandle: data._filterRenderHandle,
        isFilterHandler: data?._filterRenderHandle?.isFilterHandler,
        bffSearchBannerCardInfo: bffSearchBannerCardResult.code === '0' ? bffSearchBannerCardResult.info : {},
        bffCurrentCat,
        bffPolicyBanner,
        ...needExposeFilterInfo,
        bffCccOperationSeo,
      }

      // sheinPick页面导航数据
      if (data.navTabs) {
        resultData.picksMenuList = data.navTabs?.picksMenuList || []
        resultData.bgBanner = data.navTabs?.bgBanner || {}
      }

      if (isUserBehavior) {
        // eslint-disable-next-line no-unused-vars
        let { products, ...res } = resultData.bffProductsInfo
        Object.assign(resultData, {
          ...res
        })
      }

      if (data.picksMenuList?.length) {
        resultData.picksMenuList = data.picksMenuList
      }
      return resultData
    } else {
      const result = await nodeRequest({
        withoutAbort: requestInfo.query?.withoutAbort,
        toRouteForward: toRoute
      })
      return result
    }
  } catch (e) {
    console.error(e)
    // 用于点后刷判断错误类型
    if (isPicked) {
      return { error: e }
    }
    return {}
  }
}

const getNodeRequest = (requestInfo, newSrcTabPageId, toRoute) => {
  const params = generateParams(requestInfo, newSrcTabPageId, toRoute)
  // toRouteForward 用于处理无 Route实例的情况，例如：实时反馈半屏场景(注意栈溢出报错问题！！！)

  return ({
    useBffProducts,
    useBffFilters,
    useBffCccConfig,
    withoutAbort,
    useBffPolicyBanner,
    toRouteForward
  } = {}) =>
    apiCache.request(
      {
        ...params,
        useBffProducts,
        useBffFilters,
        useBffCccConfig,
        useBffPolicyBanner,
        withoutAbort,
      },
      { toRouteForward },
    )
}

/**
 * 检测是否数据已经缓存
*/
const getProductListApiCache = (requestInfo, newSrcTabPageId, toRoute) => {
  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)
  if (isSearch) {
    const { goodsParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(goodsParams)
  }
}

let prehtmlFlag = false // 单页水合时，不发起预请求
function changeSpaHydrationReqeustState(value) {
  prehtmlFlag = value
}
function getSpaHydrationReqeustState() {
  return prehtmlFlag
}

/**
 * 路由的预请求
 * */
function handleProductListPreRequestBeforeEachRoute(to, from) {
  console.log('路由的预请求', from)
  if (prehtmlFlag) return // 单页水合时，不发起预请求
  const isProductListRoute = PRODUCT_LIST_ROUTE_NAME.includes(to.name)
  if (!isProductListRoute) return

  if (from.name === 'preload-request') {
    // 点击pointer触发的预请求， 由于快于beforeEach，所以在这里触发进行校验一次
    userTracekPreCheckEnd({ featureKey: 'click_refresh', to })
  }

  if (isGeneralProductList(to)) {
    markPoint({ eventName: 'RouterStartTrigger', measureTo: 'RouterEndTrigger' })
  }

  setCurrentToRoute(to)
  const isSpaRouteChange = (fromRoute) => Boolean(fromRoute.name)
  const isSameRouteChange = (to, from) => to.name === from.name
  if (isSpaRouteChange(from)) {
    if (!isSameRouteChange(to, from)) {
      requestProductListApi({ requestInfo: defaultRequestInfo(to.query), newSrcTabPageId: undefined, toRoute: to })
    }
  }
}

// to, from
function handleProductListPreRequestAfterEachRoute(to) {
  if (!prehtmlFlag && isGeneralProductList(to)) {
    markPoint({ eventName: 'RouterEndTrigger', measureTo: 'PageWaitInit' })
  }
}

if (typeof window !== 'undefined') {
  window.apiCacheProducts = apiCache
}

const abortRequest = (abortReason) => {
  showRecentDaysTagAbortionInstance?.abort?.(abortReason)
  dailyNewFilterAbortionInstance?.abort?.(abortReason)
  dailyNewCloudTagAbortionInstance?.abort?.(abortReason)
  cccFeedbackAbortionInstance?.abort?.(abortReason)
  abortionInstance?.abort?.(abortReason)
  goodsAbortionInstance?.abort?.(abortReason)
  hypernymGoodsAbortionInstance?.abort?.(abortReason)
  searchFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityPicNavAbortionInstance?.abort?.(abortReason)
  selectionAndEntityCloudTagAbortionInstance?.abort?.(abortReason)
  pickedFilterAbortionInstance?.abort?.(abortReason)
  pickedCloudTagAbortionInstance?.abort?.(abortReason)
  sheinPicksNavTabAbortionInstance?.abort?.(abortReason)
  feedbackRecFilterAbortionInstance?.abort?.(abortReason)
  feedbackRecCloudTagAbortionInstance?.abort?.(abortReason)
  searchBannerCardAbortionInstance?.abort?.(abortReason)
  discountPageInfoApiCache?.abort?.(abortReason)
}

/**
 * 触摸提前预请求方法。让请求提前触发
 * */
const pointerOverGoodsRequest = new PointerOverGoodsRequest({
  fetch: (path) => {
    const toRouteParams = generateRouterParams(path)
    if (!toRouteParams) return
    handleProductListPreRequestBeforeEachRoute(toRouteParams, { name: 'preload-request' })
  },
  cancelToken: () => abortRequest(),
})

// 做kids筛选时候，清除对应接口缓存
const clearApiCacheInKidsFilter = () => { 
  clearNodeApiCache()
  clearGoodsApiCache()
  clearHypernymGoodsApiCache()
  clearSearchFilterApiCache()
  clearSearchBannerCardApiCache()
  clearRelatedSearchApiCache()
  clearSelectionAndEntityFilterApiCache()
  clearSelectionAndEntityCloudTagApiCache()
  clearSheinPicksNavTabApiCache()
}

export {
  abortionInstance,
  abortRequest,
  requestProductListApi,
  setCurrentToRoute,
  handleProductListPreRequestBeforeEachRoute,
  handleProductListPreRequestAfterEachRoute,
  pointerOverGoodsRequest,
  changeSpaHydrationReqeustState,
  getProductListApiCache,
  clearApiCache,
  clearApiCacheInKidsFilter,
  getSpaHydrationReqeustState,
}
