import { reg } from 'public/src/pages/common/biz_helper/shein_picks.js'
import { FEEDBACK_REC, PICKED } from 'public/src/pages/rec_product_list/js/constant.js'
import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
import { ORIGIN_QUERY_FILTER_KEYS, SEARCH_SOURCE_ENUM } from 'public/src/pages/common/search_words/searchConst.js'
import { getCurrentToRoute } from './utils'
import { isStore } from './bffParamsHandle/utils'

function isGeneralProductList(route) {
  return route.name === 'product-list-v2'
}

function isSearchProductList(route) {
  return route.name === 'search-product-list'
}
function isStoreRoute(route){
  return route.name === 'page_store'
}

function isPicksRoute(route) {
  return route.name === 'shein-picks'
}

// 信息流和实时反馈落地页
function isRecProductListRoute(route) {
  return route.name === 'picked-info-list'
}

// 实时反馈落地页
function isFeedBackRecRoute(route) {
  return route.name === 'feedback-rec-list'
}

function isRouteNameMatch(route, match) {
  return route.name === match
}

function fixSearchSource(params) {
  const { pic_nav_id = '', search_source = '' } = params
  let fix = ''
  // 图文导航
  if (pic_nav_id) {
    fix = SEARCH_SOURCE_ENUM.IMG_NAV
  }
  // 有图文导航走图文导航，没有图文导航在判断筛选
  const hasFilter = ORIGIN_QUERY_FILTER_KEYS.some(key => params[key])
  // 需求TR-19517
  // 将线上点击图文导航后的search_source由1更改为6，不区分是否为点击后走筛选逻辑、或者点击后走query入参，统一改为6
  // && search_source !== '6'处理，当前图文导航点击后变成query（图文模块导行消失）, 这个时候再点击筛选应该继续为6，不应该变为1
  if (!pic_nav_id && hasFilter && search_source !== '6') {
    fix = SEARCH_SOURCE_ENUM.USER_INPUT
  }
  return fix || search_source
}

function getRecListTypeByRoute(route) {
  const path = route.path
  let pageType = ''
  if (/picked-info-products/.test(path)) {
    pageType = PICKED
  } else if (/feedback-rec-products/.test(path)) {
    pageType = FEEDBACK_REC
  }
  return {
    type: pageType,
  }
}

/**
 * 通过路由的 path 来获取列表请求的类型和路由 id
 * @param route
 * @returns {{routeId: *, sub_type: *, type: string}|{type: string}|{}|{routeId: *, type: string}|{routeId: string, type: string}|{hrefTarget: string}}}
 */
function getListTypeAndRouteIdByRoute(route) {
  const path = route.path || location.pathname

  let match = path.match(/-c-(\d+)\.html$/)
  if (match) {
    return {
      type: 'entity',
      routeId: match[1],
    }
  }

  match = path.match(/((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/)
  if (match) {
    return {
      type: 'selection',
      sub_type: match[2],
      routeId: match[3],
    }
  }

  match = path.match(/sellingpoint-\d+-(\d+)-.+\.html$/)
  if (match) {
    return {
      type: 'selling',
      routeId: match[1],
    }
  }

  match = path.match(/pdsearch\/([^\/]+)\/?$/)
  if (match) {
    return {
      type: 'search',
      routeId: decodeURIComponent(match[1]).slice(0, 150),
    }
  }

  match = path.match(/daily-new\.html$/)
  if (match) {
    return {
      type: 'daily_new',
    }
  }

  match = path.match(reg)
  if (match) {
    return {
      hrefTarget: getHrefTarget({ path }), // 匹配 ccc navList
    }
  }

  return {}
}

function getHrefTarget({ path }) {
  const { PICKS_LINKS } = gbCommonInfo || {}
  const match = path.match(reg)?.[1]
  const LINK_MAP = Object.fromEntries(Object.entries(PICKS_LINKS).map(([a, b])=>[b, a]))

  return LINK_MAP[match]
}

/**
 * 通过存储在 store 中的状态来获取列表请求的类型和路由 id
 * @param store
 * @param route
 * @returns {{}|{routeId: (string|*), sub_type: (string|*), type: *}|{routeId: *, type: *}|{hrefTarget: string, navId: string}}
 */
function getListTypeAndRouteIdByStore(store, route, navId) {
  const { catInfo = {}, searchKeywords = {} } = store
  const type = catInfo.type
  const path = route.path || location.pathname
  switch (type) {
    case 'entity':
      return {
        type,
        routeId: catInfo.entity_id,
      }
    case 'selection':
      return {
        type,
        routeId: catInfo.select_id,
        sub_type: catInfo.sub_type,
      }
    case 'store':
      return {
        type: 'selection',
        store_code: catInfo.store_code,
        routeId: catInfo.select_id,
        sub_type: 'selection',
      }
    case 'selling':
      return {
        type,
        routeId: catInfo.tag_val_id,
      }
    case 'search':
      return {
        type,
        routeId: searchKeywords.is_suggest_res
          ? searchKeywords.suggest_words
          : searchKeywords.origin_words,
      }
    case 'picks': {
      return {
        hrefTarget: getHrefTarget({ path }), // 匹配 ccc navList
        navId,
      }
    }
  }

  return {
    type,
  }
}

function getListTypeAndRouteId(requestInfo, store, route) {
  const { type, navId } = requestInfo || {}
  if(isStoreRoute(route)){
    let _store = store
    if(requestInfo.query.isStoreListPreRequest){ // 预请求
      _store = {
        ...store,
        catInfo: {
          ...requestInfo.query,
          type: 'store',
          pageName: 'page_store',
          sub_type: 'selection',
        }
      }
    }
    return getListTypeAndRouteIdByStore(_store, route, navId)
  }
  if (isGeneralProductList(route) || isSearchProductList(route) || isPicksRoute(route)) {
    if (type !== 'firstload' && store.catInfo.type) {
      return getListTypeAndRouteIdByStore(store, route, navId)
    } else {
      return getListTypeAndRouteIdByRoute(route)
    }
  } else if (isRouteNameMatch(route, 'discount-channel-list')) {
    return {
      type: 'discount_channel',
    }
  } else {
    return getRecListTypeByRoute(route)
  }
}

const getCateIds = ({ catInfo, stateResults, toRoute, requestInfo }) => {
  if(isStore(toRoute)){
    return requestInfo.query.child_cat_id || catInfo.child_cat_id
  }
  if (catInfo.type === 'search') {
    return catInfo.child_cat_id || stateResults.filterAbtParam?.currentId
  }

  if (catInfo.type === 'selection') {
    return catInfo.child_cat_id
  }
  return catInfo.cat_id || ''
}

const getListGetterKey = (toRoutes = null) => {
  const toRoute = toRoutes || getCurrentToRoute() || {}
  let getterKey = ''
  if(isStoreRoute(toRoute)){
    getterKey = 'store_pages'
  }else if (isGeneralProductList(toRoute)) {
    getterKey = 'productList_v2'
  } else if (isSearchProductList(toRoute)) {
    getterKey = 'search-product-list'
  } else if (isPicksRoute(toRoute)) {
    getterKey = 'shein-picks'
  } else if (isRecProductListRoute(toRoute)) {
    getterKey = 'rec_product_list'
  } else if (isRouteNameMatch(toRoute, 'discount-channel-list')) {
    getterKey = 'discount_channel_list'
  } else if(isFeedBackRecRoute(toRoute)) {
    // 实时反馈落地页，上面的是信息流落地页，历史不敢动，单独自行处理
    getterKey = 'feedback_rec'
  } else {
    getterKey = ''
  }
  return getterKey
}

/**
 * 获取商品列表请求参数
 * @param requestInfo - 在业务场景下, 手动调用此函数生成参数时, 传入的请求相关信息, 用来生成特定的请求参数
 * @returns {{headers: {}, qs: {}}|{}}
 */
function getProductListParams(requestInfo, newSrcTabPageId = '', toRoutes = null) {
  try {
    const toRoute = toRoutes || getCurrentToRoute() || {}
    const state = _gb_app_?.$store?.state || {}
    const getters = _gb_app_?.$store?.getters
    let getterKey = getListGetterKey(toRoute)

    const goods = getters?.[`${getterKey}/goods`] || []
    const catInfo = getters?.[`${getterKey}/catInfo`] || {}
    const searchKeywords = getters?.[`${getterKey}/searchKeywords`] || {}
    // const locals = getters?.[`${getterKey}/locals`] || {}
    const language = getters?.[`${getterKey}/language`] || {}
    const atomicParams = getters?.[`${getterKey}/atomicParams`] || {}
    const sheinClubInfo = getters?.[`${getterKey}/sheinClubInfo`] || {}
    const stateResults = state[getterKey]?.Results || {}

    let viewedGoods
    // 只有加载更多才带上 viewedGoods
    if ((isGeneralProductList(toRoute) || isSearchProductList(toRoute) || isPicksRoute(toRoute) || isStoreRoute(toRoute)) && requestInfo.type === 'nextpage' && requestInfo.query.page <= 12) {
      let viewedGoodsIdx = Number(requestInfo?.query?.goodsMaxViewedIdx) || 240
      viewedGoods = goods.slice(0, viewedGoodsIdx).map(goodItem => {
        const goodsId = goodItem.goods_id
        const spuId = goodItem.productRelationID
        return goodsId + '-' + spuId
      }).join(',')
    } else {
      viewedGoods = ''
    }

    const params = {
      ...getListTypeAndRouteId(requestInfo, { catInfo, searchKeywords }, toRoute),
      ...requestInfo.query,
      requestType: requestInfo.type,
      // _currency: isGeneralProductList(toRoute) ? locals.currency : '',
      i18n: !Object.keys(language).length,
      // 双语搜索后续请求需带上强制标记
      force_suggest: !!searchKeywords.double_lang_correct,
      // 请求需带上用户当前已经浏览过的商品项(最多 240 个商品, 也就是 12 页), 避免后端返回相同 SPU 的商品
      // 格式：{{goods_id}}-{{spu_id}},{{goods_id}}-{{spu_id}}
      viewed_goods: viewedGoods,
      // 将这个常量带到服务端, 避免在服务端也维护一份同样的数据
      asyncPromotionIds: PROMOTION_CONFIG.FetchPromotionLableTypeIds.join(),
      // 已经请求过的促销 id, node 层会过滤掉这些 id, 避免重复请求
      // 服务端为首次请求, 默认为空
      fetchedPromotionIds: typeof window === 'undefined' ? '' : Object.keys(window.promotionCenter?.cache.integrityPromotionInfo || {}).join(),
      // 是否请求付费会员, 在前端存起来, 每次请求的时候带给后端 (前端维护状态比后端容易)
      reqSheinClub: atomicParams.reqSheinClub ?? sheinClubInfo.reqSheinClub,
      isPaid: atomicParams.isPaid ?? sheinClubInfo.isPaid,
      sheinClubType: atomicParams.sheinClubType ?? sheinClubInfo.sheinClubType,
      cate_ids: getCateIds({ catInfo, stateResults, toRoute, requestInfo })
    }
    
    if (params.type === 'search') {
      // 新增搜索来源，排除店铺搜索
      if (params.search_type !== 'store') {
        params.search_source  = fixSearchSource(params)
      }
      // 新增 new_src_tab_page_id
      params.new_src_tab_page_id = newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-'
      params.src_identifier_pre_search = gbCommonInfo?.src_identifier_pre_search || ''
    }

    // 抹掉空参
    Object.keys(params).forEach(key => {
      if ((key === 'min_price' || key === 'max_price') && params[key] !== '') return
      if (key === 'source_status' && params[key] === 0) return
      if (key === 'toRoute') delete params[key] // 删除 toRoute
      !params[key] && delete params[key]
    })

    return params
  }  catch (err) {
    return {}
  }
}

export default getProductListParams
