import { ROUTE_REGEX } from '../constant.js'
import { getViewedGoods, getCommonParams, getVuexInfo, getListMainParams  } from './common.js'
import { getCurrentToRoute } from '../utils'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'
import { getRefreshListStrategyParams } from './refreshListStrategyParams.js'

const subTypeTotypeId = new Map([
  ['entity', 1],
  ['selection', 9]
])

const getNavParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}

  let params = {}

  const sheinPicksUrlMatch = toRoute.path?.match(ROUTE_REGEX.sheinPicks)
  if (query.hrefTarget) {
    params.pageId = query.hrefTarget
  } else if (sheinPicksUrlMatch?.[1]) {
    params.pageName = sheinPicksUrlMatch?.[1]
  }

  // 预览参数
  if (query.cacheKey) {
    params.cacheKey = query.cacheKey
  }
  
  return params
}

const getGoodsParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { goods, catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'sheinPicksListPage')

  const { 
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds, 
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex, 
    default_child_birthday,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    cat_id: query.picks_id,
    type: subTypeTotypeId.get(query.sub_type),
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: filterMallCode || urlMallCode,
    page_name: catInfo.pageName,
    sort: query.sort || '',
    source: query.source || '',
    styleType: query.styleType || '',
    source_status: query.source_status || '',
    adp: getGoodsAdp(query),
    userpath: query.userpath || '',
    filter_goods_infos: getViewedGoods(toRoute, requestInfo, goods),
    _type: 'sheinPicks',


    isClickRefresh: query.isClickRefresh || 0,
    frontFilterGoods: query.frontFilterGoods,

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
    ...refreshListStrategyParams,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选相关
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  const { 
    quickship,
    filter,
    catId,
    filterTagIds,
    
    maxPrice,
    minPrice,
    tagIds,
    mallCode,
    // lastParentCatId, 
    chooseIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,   
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    cat_id: query.picks_id,
    type: subTypeTotypeId.get(query.sub_type),
    quickship,
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    last_parent_cat_id: query.last_parent_cat_id,
    choosed_ids: chooseIds, // 已选中的分类id和其所有父类id用逗号拼接
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: mallCode,
    page_name: catInfo.pageName,
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    _type: 'sheinPicks',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  
  const { 
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    urlMallCode,
    filterMallCode,
    tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    srcType
  } = getCommonParams(query, searchKeywords)


  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    ...mainParams,
    cat_id: query.picks_id,
    type: subTypeTotypeId.get(query.sub_type),
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    child_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    page_name: catInfo.pageName,
    page: query.page || 1,
    limit: query.limit || 10,
    kidRandom: query.kid_random || '', // 避免缓存
    styleType: query.styleType || '',
    srctype: srcType === 'home' ? 'homepage' : srcType,
    _type: 'sheinPicks',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

const getCccOperationRequestInfo = (requestInfo) => {
  const query = requestInfo.query || {}

  const subTypeTotypeId = new Map([
    ['entity', 1],
    ['selection', 2]
  ])

  const sceneKeys = ['mobile_seo_text']

  if (query.sub_type === 'entity') {
    sceneKeys.push('mobile_buyer_show')
  }

  return {
    form: {
      cate_id: query.picks_id,
      cate_type: subTypeTotypeId.get(query.sub_type),
    },
    sceneKeys
  }
}


/**
 * 
 * @param {Object} requestInfo 
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query 
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns 
 */
export const getSheinPicksGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    cloudTagParams: getCloudTagParams(requestInfo),
    navParams: getNavParams(requestInfo),
    cccOperationParams: getCccOperationRequestInfo(requestInfo)
  }
}

