import dayjs from 'dayjs'
import { getCommonParams, getVuexInfo, getListMainParams  } from './common.js'
import { getCurrentToRoute } from '../utils'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'
import { getRefreshListStrategyParams } from './refreshListStrategyParams.js'
import { getCccOperationRequestInfo } from './searchListPage.js'
const getGoodsParams = (requestInfo, newSrcTabPageId) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')

  const { 
    quickship,
    filter,
    catId,
    brandId,
    date,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds, 
    channelId,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    choosed_nav_pos: query.pic_nav_pos || '',
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: filterMallCode || urlMallCode,
    src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
    entranceType: query.entranceType || '',
    sort: query.sort || 0,
    source: query.source || '',
    styleType: query.styleType || '',
    source_status: query.source_status || '',
    adp: getGoodsAdp(query),
    srctype: 'other',
    page_name: 'page_daily_new',
    daily: query.daily,
    _type: 'daily_new',

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
    ...refreshListStrategyParams,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 筛选相关
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')
  const { 
    quickship,
    filter,
    catId,
    brandId,
    date,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    mallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    // lastParentCatId, 
    chooseIds,  
    filterBrandIds,
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    last_parent_cat_id: query.last_parent_cat_id,
    choosed_ids: chooseIds, 
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: mallCode,
    page_name: 'page_daily_new',
    sort: query.sort || '',
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    ...refreshListStrategyParams,
    daily: query.daily,
    _type: 'daily_new'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const refreshListStrategyParams = getRefreshListStrategyParams(requestInfo, toRoute, 'selectListPage')
  const { 
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
  } = getCommonParams(query, searchKeywords)

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    ...mainParams,
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    cat_ids: query.cat_ids,
    cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    sort: query.sort || '',
    kidRandom: query.kid_random || '', // 避免缓存
    styleType: query.styleType || '',
    daily: query.daily,
    page_name: 'page_daily_new',
    ...refreshListStrategyParams,
    _type: 'daily_new'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云中的date标签数据
const getCategoryDayTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)
  const { 
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    mallCode,
    tagIds
  } = getCommonParams(query, searchKeywords)

  const time = dayjs(Date.now())
  const today = time.format('YYYY-MM-DD')

  let params = {
    ...mainParams,
    today,
    quickship,
    filter,
    cat_ids: query.cat_ids,
    cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    mall_code_list: mallCode,
    styleType: query.styleType || '',
    tag_ids: tagIds,
    _type: 'daily_new'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

export const getDailyNewGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    cloudTagParams: getCloudTagParams(requestInfo),
    categoryDayTagParams: getCategoryDayTagParams(requestInfo),
    cccSeoParams: {
      cateId: '',
      cateType: 7
    },
  }
}
