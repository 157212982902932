import { ROUTE_REGEX } from '../constant.js'
import { getSearchRequestQueue } from './searchListPage.js'
import { getSelectRequestQueue } from './selectListPage.js'
import { getRealRequestQueue } from './realListPage.js'
import { getSellingRequestQueue } from './sellingPage.js'
import { getSheinPicksRequestQueue } from './sheinPicksPage.js'
import { getDailyNewRequestQueue } from './dailyNewPage.js'
import { getPickedRequestQueue } from './pickedListPage.js'
import { getUserBehaviorRequestQueue } from './userBehavior.js'
import { getFeedbackRecRequestQueue } from './feedbackRecPage.js'
import { getDiscountRequestQueue } from './discountListPage.js'
import { getRealHalfRequestQueue } from './realHalfDrawer.js'


const matchPageType = (toRoute) => {
  const pageType = {
    isSearch: false,
    isSelect: false,
    isReal: false,
    isPicked: false,
    isUserBehavior: false,
    isFeedBackRec: false,
    isDiscount: false,
    isDailyNew: false,
    isSheinPicks: false,
    isRealHalf: false
  }
  if (!toRoute?.path) {
    return pageType
  }
  // @tips 尽量减少正则表达式的匹配次数
  if (toRoute.path.match(ROUTE_REGEX.real) && toRoute.drawerType === 'entityDrawer') {
    // 类目反馈半屏
    pageType.isRealHalf = true
  } else if (toRoute.path.match(ROUTE_REGEX.search)) {
    pageType.isSearch = true
  } else if (toRoute.path.match(ROUTE_REGEX.select)) {
    pageType.isSelect = true
  } else if (toRoute.path.match(ROUTE_REGEX.real)) {
    pageType.isReal = true
  } else if (toRoute.path.match(ROUTE_REGEX.picked)) {
    pageType.isPicked = true
  } else if (toRoute.path.match(ROUTE_REGEX.pickedProducts)) {
    pageType.isUserBehavior = true
  } else if (toRoute.path.match(ROUTE_REGEX.feedbackRec) || toRoute.drawerType === 'feedbackRec') {
    //兼容实时反馈半屏
    pageType.isFeedBackRec = true
  } else if (toRoute.name === 'discount-channel-list') {
    pageType.isDiscount = true
  } else if (toRoute.path.match(ROUTE_REGEX.daily_new)) {
    pageType.isDailyNew = true
  } else if (toRoute.path.match(ROUTE_REGEX.sheinPicks)) {
    pageType.isSheinPicks = true
  } else if (toRoute.path.match(ROUTE_REGEX.selling)) {
    pageType.isSelling = true
  }

  return pageType
}

const getBffRequestQueue = async (requestInfo, toRoute, newSrcTabPageId) => {

  const {
    isSelling,
    isSearch,
    isSelect,
    isReal,
    isPicked,
    isUserBehavior,
    isFeedBackRec,
    isDiscount,
    isDailyNew,
    isSheinPicks,
    isRealHalf,
  } = matchPageType(toRoute)

  // 搜索结果页
  if (isSearch) {
    return getSearchRequestQueue({ requestInfo, newSrcTabPageId })
  } 
  
  // 选品列表页，店铺--只有命中bff 
  const isStore = toRoute?.name === 'page_store'
  if (isSelect || isStore) {
    return getSelectRequestQueue({ requestInfo, newSrcTabPageId, isStore })
  }

  // 真实列表页
  if (isReal) {
    return getRealRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 卖点列表页
  if (isSelling) {
    return getSellingRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // shein picks页
  if (isSheinPicks) {
    return getSheinPicksRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // daily new列表页
  if (isDailyNew) {
    return getDailyNewRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 信息流落地页
  if (isPicked) {
    return getPickedRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 用户行为落地页（信息流用户行为内容体点击进入）
  if (isUserBehavior) {
    return getUserBehaviorRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 实时反馈落地页
  if (isFeedBackRec) {
    return getFeedbackRecRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 折扣频道落地页
  if (isDiscount) {
    return getDiscountRequestQueue({ requestInfo })
  }

  // 类目推荐半屏(就是不完全的真实分类页， 单拆出来就是怕污染真实分类页的逻辑), 以后再有半屏都可以在这扩展
  if (isRealHalf) {
    return getRealHalfRequestQueue({ requestInfo, newSrcTabPageId, toRoute })
  }
  
  return {}
}

export {
  getBffRequestQueue
}
