import { promiseAsyncAuto } from '../asyncAuto.js'
import { getRealGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  selectionAndEntityFilterApiCache,
  selectionAndEntityCloudTagApiCache,
  breadcrumbCache,
} from '../api/bffApi.js'
import { requestPicNav, getCommonRequestQueue } from './common.js'
import { generateRealSelectBffFilterInfo } from '../utils'

const getRealRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, picNavParams, cloudTagParams, cccSeoParams, cccOperationParams, policyBannerParams } = getRealGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ requestInfo, cccSeoParams, cccOperationParams, policyBannerParams }),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'entity',
      params: goodsParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      picNavParams._requestType = requestInfo.type   // 传入，里面判断删除
      const result = await promiseAsyncAuto({
        bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
        bffPicNavResult: requestPicNav({ requestInfo, picNavParams }), // 图文导航
        bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
      })
      cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
    },
    // 面包屑 currentCat
    currentCat: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, null)

      cb(null, {
        bffCurrentCat: true,
        _from: 'bff',
      })
    },
    // 面包屑 parentCats
    parentCats: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, null)

      const cat_id = goodsParams.cat_id
      const result = await breadcrumbCache.request({
        params: {
          cat_id
        }
      })

      const parentCats = result.info?.categories?.[0] || {} 

      cb(null, {
        ...parentCats,
        _from: 'bff',
      })
    },
  }
  return requestQueue
}

export {
  getRealRequestQueue
}
